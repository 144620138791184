import React from 'react';
import { graphql } from 'gatsby';

import HeroTypeC from '../../components/Heroes/HeroTypeC';
import BannerTypeColored from '../../components/Banners/BannerTypeColored';
import BannerInfoTypeA from '../../components/Banners/BannerInfoTypeA';
import TripleBanner from '../../components/Location/TripleBanner';
import MultiArticleV2 from '../../components/Articles/MultiArticleV2';
import FaqSection from '../../components/FAQ/FaqSection';
import BannerTypeA from '../../components/Banners/BannerTypeA';
import SEO from '../../components/SEO/SEO';

export default function KingstonDelivery({ data }) {
  const hero_type_c = data.strapiKingstonDelivery.Hero;
  const info_banner_colored = data.strapiKingstonDelivery.Info_Banner_Type_C;
  const info_banner_type_a = data.strapiKingstonDelivery.Info_Banner_Type_A;
  const triple_banner = data.strapiKingstonDelivery.Triple_Info_Banner;
  const multi_article = data.strapiKingstonDelivery.Multi_Articles_Section;
  const faq = data.strapiKingstonDelivery.FAQ;
  const banner_type_bottom = data.strapiKingstonDelivery.Blog_Banner_Bottom;

  return (
    <>
      <SEO
        title="Weed Delivery in Kingston | House of Cannabis"
        description="If you’re not interested in going out, you no 
                  longer have to go without amazing House of Cannabis products. 
                  We’re now offering weed delivery in Kingston!"
      />
      <HeroTypeC
        title={hero_type_c.Title}
        subtitle={hero_type_c.Content}
        heroImage={
          hero_type_c.BackgroundImage.localFile.childImageSharp.gatsbyImageData
        }
      />

      <BannerTypeColored
        description={info_banner_colored.Content}
        buttonLabel={info_banner_colored.Button.Label}
        buttonLink={info_banner_colored.Button.Custom_Link_Slug}
        center
      />

      <BannerInfoTypeA
        title={info_banner_type_a.Title}
        description={info_banner_type_a.Content}
        bgImage={
          info_banner_type_a.Background_Image.localFile.childImageSharp
            .gatsbyImageData
        }
      />

      <TripleBanner
        title={triple_banner.Title}
        banners={triple_banner.Single_Triple_Info_Banner}
      />

      <MultiArticleV2
        leftarticle={multi_article.Left_Mutli_Article}
        rightarticle={multi_article.Right_Multi_Article}
      />

      <FaqSection faqdata={faq.FAQs} title={faq.Title} />

      <BannerTypeA
        title={banner_type_bottom.Title}
        description={banner_type_bottom.Content}
        bannerImage={
          banner_type_bottom.Background_Image.localFile.childImageSharp
            .gatsbyImageData
        }
        link="/blog/"
        buttonLabel="CHECK OUT BLOGS"
      />
    </>
  );
}

export const query = graphql`
  query {
    strapiKingstonDelivery {
      Hero {
        Content
        Title
        BackgroundImage {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      Info_Banner_Type_C {
        Content
        Button {
          Custom_Link_Slug
          Label
        }
      }
      Info_Banner_Type_A {
        Content
        Title
        Background_Image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      Triple_Info_Banner {
        Title
        Single_Triple_Info_Banner {
          Content
          Header
          Image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
      Multi_Articles_Section {
        Left_Mutli_Article {
          article {
            BannerImage {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            Preview_Text
            PublishedDate
            Title
            slug
          }
        }
        Right_Multi_Article {
          article {
            Preview_Text
            BannerImage {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            PublishedDate
            Title
            slug
          }
        }
      }
      FAQ {
        FAQs {
          Answer
          Link_Label
          Link_Slug
          Question
        }
        Title
      }
      Blog_Banner_Bottom {
        Content
        Title
        Background_Image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
