import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';

import ReactMarkdown from "react-markdown";
import rehypeRaw from 'rehype-raw'


const MOBILE_BREAKPOINT = '992px'


const BGColor = styled.div`
    background: var(--darkgreen);
    background: linear-gradient(287.58deg, #A1C654 20.01%, #B0E640 91.64%);
`;

const Wrapper = styled.div`
    margin: 70px auto;
    max-width: 1200px;
    margin-top: -100px;
    @media screen and (max-width: 1200px){
        margin-top: 10px;
    }

    .bg-image,
    .bg-image::after,
    .bg-image::before{
        background-size: cover;
        height: 58vh;
        background-size: cover;
        @media (max-width: ${MOBILE_BREAKPOINT}){
            width: 100%;
            height: 100%;
            min-height: 105vh;
            background-size: 450% 100%;
        }
    }
`;


const Container = styled.div`
    padding: 47px 80px;
    text-align: ${props => props.center ? 'center' : 'left'};
    align-items: center;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;


    .hoc-button-outline {
        margin-top: 70px;
        display: inline-block;
        border-color: white;
        color: white;
    }
    @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
        min-height: 420px;
        padding: ${props => props.center ? '50px 40px 60px' : '30px 100px 140px 30px'};
    }
`;

const Description = styled.div`
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
    color: white;
    opacity: 1;
    @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
        font-size: 15px;
    }
    h2{
        color: white;
        opacity: 1;
    }
`;


export default function BannerInfoTypeB({description, buttonLabel, buttonLink, center}){
    
    let b_label = 'SHOP ONLINE';
    if(buttonLabel){
        b_label = buttonLabel
    }
    else{
        b_label = 'SHOP ONLINE';
    }
    
    return(
        <Wrapper>
            <BGColor>
                <Container center={center}>
                    <Description center={center}>
                        <ReactMarkdown rehypePlugins={[rehypeRaw]} children={description} />
                    </Description>
                    <Link to={buttonLink} className="hoc-button-outline">{b_label}</Link>
                </Container>
            </BGColor>
        </Wrapper>
    );
}